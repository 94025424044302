import React from "react"

const InternationalIndex = ({ data }) => {
    React.useEffect(() => {
        window.location.href = "https://google.com"
    })
    return (
        <>

        </>
    )
}

export default InternationalIndex